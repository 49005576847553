﻿/**
 * JQuery Float Panel plugin
 * 
 * IMPORTANT: Modified to add defaultStyle for use in FloatingTabPanel
 * 
 * Dependencies (must be loaded manually and be globally available before this script)
 * - jQuery
 * - jQuery UI draggable
 */
(function ($) {
    var defaults = {
        showDelay: 700,
        collapsed: false,
        collapseBtnSelector: '.float-panel-btn-collapse',
        expandBtnSelector: '.float-panel-btn-expand',
        collapsedClass: 'collapsed',
        defaultStyle: ''
    };

    var publicApi = {
        expand: expand,
        collapse: collapse
    };

    $.fn.floatingTabPanel = function () {
        if (arguments.length > 0 && typeof arguments[0] === 'string') {
            var publicApiMethod = arguments[0];
            if (!publicApi[publicApiMethod]) {
                throw new Error('Invalid floatContainer method: ' + publicApiMethod);
            }
            publicApi[publicApiMethod].apply(this);
        }
        else {
            var options = typeof arguments[0] === 'object' ? arguments[0] : {};
            init.call(this, $.extend(defaults, options));
        }

        return this;
    };

    function init(options) {
        var container = $(this);
        var collapseBtn = container.find(options.collapseBtnSelector);
        var expandBtn = container.find(options.expandBtnSelector);

        if (options.handleSelector) {
            var handle = container.find(options.handleSelector);
            container.draggable({ handle: handle });
        } else {
            container.draggable();
        }

        if (options.sizeClass) {
            container.addClass(options.sizeClass);
        }
        
        container.data('floatPanelCollapsedClass', options.collapsedClass);
        container.data('floatPanelDefaultStyle', options.defaultStyle);
        collapseBtn.click(collapse.bind(this));
        expandBtn.click(expand.bind(this));

        if (options.collapsed) {
            collapse.call(this);
        }

        if (options.showDelay > 0) {
            container.hide();
            setTimeout(function () { container.show(); }, options.showDelay);
        }
    }

    function expand() {
        var container = $(this);
        container.removeClass(container.data('floatPanelCollapsedClass'));

        // call draggable only if element has draggable class, otherwise it will throw exception
        // because draggable will not be initialized if removed from the document (e.g. by knockout)
        if (container.hasClass('ui-draggable')) {
            container.draggable('enable');
        }
    }

    function collapse() {
        var container = $(this);
        container.attr('style', container.data('floatPanelDefaultStyle')).addClass(container.data('floatPanelCollapsedClass'));

        // call draggable only if element has draggable class, otherwise it will throw exception
        // because draggable will not be initialized if removed from the document (e.g. by knockout)
        if (container.hasClass('ui-draggable')) {
            container.draggable('disable');
        }
    }
}(jQuery));
