/**
 * Knockout bootstrap pageable data table
 * https://github.com/labory/knockout-bootstrap-data-table
 */

(function () {
    ko.dataTable = {
        ViewModel: function (config) {
            var self = this;
            self.element = ko.observable();
            self.dataSource = config.dataSource || ko.observableArray([]);
            self.parent = config.parent || {};
            self.visibleColumnCount = ko.observable(1);
            self.exportedDataString = ko.observable('');
            self.exportedFilename = ko.observable('');

            self.loader = config.loader || function (pageIndex, pageSize, sortDescriptor, sortDirection, callback) {
                var sortedData = _.sortBy(self.dataSource(), function (theData) {
                    if (_.isFunction(theData[sortDescriptor])) {
                        return theData[sortDescriptor]();
                    } else {
                        return theData[sortDescriptor];
                    }
                }, sortDescriptor);
                if (sortDirection === 'desc') {
                    sortedData.reverse();
                }

                var rows = [];
                if (self.dataSource().length <= pageSize) {
                    rows = sortedData;
                } else {
                    rows = _.skipTake(sortedData, { skip: (((pageIndex === 0) ? 1 : pageIndex) - 1) * pageSize, take: pageSize });
                }

                var data = {
                    size: pageSize,
                    number: pageIndex - 1 < 0 ? 0 : pageIndex - 1,
                    content: rows,
                    totalPages: Math.ceil(self.dataSource().length / pageSize)
                };

                callback(data);
            };

            self.fixColumnVisibility = function () {
                if (!self.element())
                    return;

                var $element = $(self.element());
                var visibleColumnCount = 0;
                var $targetRows = $('tbody tr', $element);

                for (var j = 0; j < self.columns().length; j++) {
                    var shouldBeShown = self.columns()[j].visible() === true;
                    if (shouldBeShown) {
                        $('td:eq(' + j + ')', $targetRows).show();
                        $('tfoot tr td:eq(' + j + ')', $element).show();
                        visibleColumnCount++;
                    } else {
                        $('td:eq(' + j + ')', $targetRows).hide();
                        $('tfoot tr td:eq(' + j + ')', $element).hide();
                    }

                }

                self.visibleColumnCount(visibleColumnCount);
            };

            self.items = ko.observableArray(config.items || []);
            self.columns = config.columns;
            self.filteredColumns = ko.computed(function () {
                return _.filter(self.columns(), function (column) {
                    return column.canBeShown() === true;
                });
            });
            self.exportableColumns = ko.computed(function () {
                return _.filter(self.columns(), function (column) {
                    return column.canBeShown() === true || column.isExportableWhenHidden() === true;
                });
            });

            self.canExportCSV = ko.observable(config.canExportCSV === undefined ? true : config.canExportCSV);
            self.canHideShowColumns = ko.observable(config.canHideShowColumns === undefined ? true : config.canHideShowColumns);
            self.totalPages = ko.observable();
            self.pageIndex = ko.observable(0);
            self.pageSize = ko.observable(config.pageSize || 10);
            self.disablePaging = ko.observable(config.disablePaging || false);
            self.originalPageSize = ko.observable(config.pageSize || 10);
            self.pageRadius = ko.observable(config.pageRadius || 2);
            self.isFirstPage = ko.computed(function () { return self.pageIndex() === 0; });
            self.isLastPage = ko.computed(function () { return self.pageIndex() === self.totalPages() - 1; });
            self.hasRows = ko.computed(function () {
                return self.items().length > 0;
            });

            self.exportData = function () {
                if (!self.canExportCSV())
                    return;

                var headerData = new Array();
                var rowData = new Array();
                var columns = ko.utils.unwrapObservable(self.exportableColumns);

                for (var i = 0; i < columns.length; i++) {
                    if ((ko.utils.unwrapObservable(columns[i].isExportable) === true && ko.utils.unwrapObservable(columns[i].visible) === true) || ko.utils.unwrapObservable(columns[i].isExportableWhenHidden) === true) {
                        headerData.push(ko.utils.unwrapObservable(columns[i].name));
                    }
                }

                var items = ko.utils.unwrapObservable(self.dataSource);
                for (var i = 0; i < items.length; i++) {
                    var row = new Array();
                    for (var c = 0; c < columns.length; c++) {
                        if ((ko.utils.unwrapObservable(columns[c].isExportable) === true && ko.utils.unwrapObservable(columns[c].visible) === true) || ko.utils.unwrapObservable(columns[c].isExportableWhenHidden) === true) {
                            var unwrappedVal = ko.utils.unwrapObservable(items[i][columns[c].value]);
                            if (_.isDate(unwrappedVal) && launchpad.utils.isDefaultDate(unwrappedVal)) {
                                row.push('-');
                            } else {
                                if (_.isDate(unwrappedVal)) {
                                    row.push(moment(unwrappedVal).format('MM/DD/YYYY h:mm:ss a'));
                                } else {
                                    row.push(unwrappedVal);
                                }
                            }
                        }
                    }

                    rowData.push(row);
                }

                var postObj = '"' + headerData.join('","') + '"\r\n';
                for (var i = 0; i < rowData.length; i++) {
                    postObj += '"' + rowData[i].join('","') + '"\r\n';
                }

                // Figure out appropriate filename
                var filename = 'exported-data';
                if (config.exportFileName) {
                    filename = config.exportFileName + "-export";
                } else {
                    var $table = $(self.element());
                    var $panelBody = $table.parents('.panel-body');

                    if ($panelBody && $panelBody.length > 0) {
                        var $panelHeading = $panelBody.first().siblings('.panel-heading');
                        if ($panelHeading && $panelHeading.length > 0) {
                            var panelHeading = $panelHeading.first().clone();
                            panelHeading.find('button').remove();
                            panelHeading = panelHeading.text();
                            if (panelHeading && panelHeading.length > 0)
                                filename = $.trim(panelHeading).toLowerCase().replace(" ", "-") + "-export";
                        }
                    }
                }

                self.exportedFilename(filename + '.csv');
                self.exportedDataString(postObj.replace(new RegExp('%t%', 'g'), '\t'));
                return true;
            };

            self.changeColumnVisibility = function (data, evt) {
                if (ko.isComputed(data.visible) === false && ko.isObservable(data.visible) === true) {
                    data.visible(!data.visible());
                    evt.stopPropagation();
                } else {
                    toastr.error('Visible must be an observable and cannot be a computed.');
                }
            };

            self.availablePageSizes = ko.computed(function () {
                var sizes = new Array();
                var itemsLen = self.pageSize() * self.totalPages();

                //Show pageSize less than 10 if specified
                if (_.isNumber(self.originalPageSize()) && self.originalPageSize() < 10) {
                    if (itemsLen > self.originalPageSize()) {
                        sizes.push(self.originalPageSize());
                    }
                }

                sizes.push(10);
                if (itemsLen > 10)
                    sizes.push(25);
                if (itemsLen > 25)
                    sizes.push(50);
                if (itemsLen > 50)
                    sizes.push(100);

                return sizes;
            });

            self.pages = ko.computed(function () {
                var pages = [];
                var page, elem, last;
                for (page = 1; page <= self.totalPages(); page++) {
                    var activePage = self.pageIndex() + 1;
                    var totalPage = self.totalPages();
                    var radius = self.pageRadius();
                    if (page == 1 || page == totalPage) {
                        elem = page;
                    } else if (activePage < 2 * radius + 1) {
                        elem = (page <= 2 * radius + 1) ? page : "ellipsis";
                    } else if (activePage > totalPage - 2 * radius) {
                        elem = (totalPage - 2 * radius <= page) ? page : "ellipsis";
                    } else {
                        elem = (Math.abs(activePage - page) <= radius ? page : "ellipsis");
                    }
                    if (elem != "ellipsis" || last != "ellipsis") {
                        pages.push(elem);
                    }
                    last = elem;
                }
                return pages;
            });

            self.showPager = ko.computed(function () {
                var result = true;
                if (_.isArray(self.dataSource()) && _.isArray(self.availablePageSizes()) && self.availablePageSizes().length > 0) {
                    result = self.dataSource().length > self.availablePageSizes()[0];
                }
                return result && self.disablePaging() === false;
            });

            self.prevPage = function () {
                if (self.pageIndex() > 0) {
                    self.pageIndex(self.pageIndex() - 1);
                }
            };
            self.nextPage = function () {
                if (self.pageIndex() < self.totalPages() - 1) {
                    self.pageIndex(self.pageIndex() + 1);
                }
            };
            self.moveToPage = function (index) {
                self.pageIndex(index - 1);
            };
            self.reload = function () {
                var column = _.find(self.filteredColumns(), function (col) {
                    return col.isSorted();
                });

                var sortDescriptor = column ? column.sortDescriptor() : '';
                var sortDirection = column ? column.sortDirection() : '';

                self.loader(self.pageIndex() + 1, self.pageSize(), sortDescriptor, sortDirection, function (data) {
                    self.items(data.content);
                    self.pageIndex(Math.min(data.number, data.totalPages - 1));
                    self.totalPages(data.totalPages);
                    self.pageSize(data.size);
                    self.fixColumnVisibility();
                });
            };

            self.isColumnPickerVisible = ko.computed(function () {
                return self.hasRows() && self.filteredColumns().length >= 5 && self.element() && self.canHideShowColumns();
            });

            self.content = ko.computed(self.reload);

            self.activate = function () {
                for (var i = 0; i < self.filteredColumns().length; i++) {
                    self.filteredColumns()[i].visible.subscribe(function () {
                        self.fixColumnVisibility();
                    });
                }

                // Add event listener for sorting
                addEventListener('sortChanged', function (e) {
                    _.each(self.filteredColumns(), function (column) {
                        if (e.detail.sortDescriptor() !== column.sortDescriptor()) {
                            column.isSorted(false);
                        }
                    });
                    self.reload();
                });
            };

            // Kick off activate method
            self.activate();
        }
    };

    ko.dataTable.column = {
        ViewModel: function (config) {
            var self = this;
            self.name = ko.observable(config.name || '');
            self.value = config.value || '';
            self.visible = typeof config.visible !== "undefined" ? ko.observable(config.visible) : ko.observable(true);
            self.canBeShown = (config.canBeShown || ko.observable(true));
            self.showInHeader = (config.showInHeader || ko.observable(true));
            self.sortDescriptor = (config.sortDescriptor || ko.observable(self.value));
            self.sortDirection = ko.observable(config.sortDirection || 'asc');
            self.isSorted = ko.observable(config.isSorted || false);
            self.isExportable = ko.observable((config.isExportable === false) ? false : true);
            self.isExportableWhenHidden = ko.observable(config.isExportableWhenHidden === false ? false : true);
            self.canSort = ko.computed(function () {
                return self.sortDescriptor() !== 'undefined' && self.sortDescriptor() !== '';
            });
            self.isSortedAscending = ko.computed(function () {
                return self.canSort() && self.isSorted() && self.sortDirection() === 'asc';
            });
            self.isSortedDescending = ko.computed(function () {
                return self.canSort() && self.isSorted() && self.sortDirection() === 'desc';
            });

            self.sort = function () {
                if (self.canSort()) {
                    self.isSorted(true);
                    if (self.sortDirection() === 'asc') {
                        self.sortDirection('desc');
                    } else {
                        self.sortDirection('asc');
                    }

                    // TODO:// I am not sure this will work with multiple tables.
                    // TODO: What is CustomEvent?
                    var event = new CustomEvent('sortChanged', { 'detail': self });
                    dispatchEvent(event);
                }
            };
        }
    };

    var templateEngine = new ko.nativeTemplateEngine();

    templateEngine.addTemplate = function (templateName, templateMarkup) {
        $('body').append("<script type='text/html' id='" + templateName + "'>" + templateMarkup + "<" + "/script>");
    };

    templateEngine.addTemplate('ko_column_picker', '\
                <div class="row" data-bind="visible: hasRows() && (isColumnPickerVisible() || canExportCSV())">\
                    <div class="col-md-6">\
                        <div class="form-group dropdown" data-bind="visible: isColumnPickerVisible">\
                            <button data-toggle="dropdown" class="btn btn-xs btn-primary"><i class="fa fa-columns"></i>Show/Hide Columns</button>\
                            <ul class="dropdown-menu" data-bind="foreach: filteredColumns">\
                                <li data-bind="visible: name().length > 0"><a href="#" data-bind="click: $root.changeColumnVisibility"><i class="fa fa-check" data-bind="visible: visible"></i><span data-bind="text: name"></span></a></li>\
                            </ul>\
                        </div>\
                    </div>\
                    <form class="col-md-6 form-group text-right" data-bind="visible: hasRows, submit: function(element){ download(ko.dataFor(element).exportedDataString(), ko.dataFor(element).exportedFilename(), \'text/csv\'); return false; }">\
                        <button type="submit" class="btn btn-primary btn-xs" data-bind="visible: canExportCSV, click: exportData"><i class=\"fa fa-download\"></i>Export to CSV</button>\
                    </form>\
                </div>\
            ');

    templateEngine.addTemplate("ko_table_header", '\
                        <thead>\
                            <tr data-bind="foreach: filteredColumns" >\
                            <!-- ko if: showInHeader -->\
                               <th data-bind="visible: visible, click: sort, style: { cursor: canSort ? \'pointer\' : \'default\'}"><span data-bind="text: name"></span>\
                                    <i data-bind="visible: isSortedAscending" class="fa fa-caret-up"></i><i data-bind="visible: isSortedDescending" class="fa fa-caret-down"></i>\ </th>\
                            <!-- /ko -->\
                            </tr>\
                        </thead>');

    templateEngine.addTemplate("ko_table_body", '\
                        <tbody data-bind="foreach: items">\
                           <tr data-bind="foreach: $parent.filteredColumns">\
                               <td data-bind="text: typeof value == \'function\' ? value($parent) : $parent[value] "></td>\
                            </tr>\
                        </tbody>');

    templateEngine.addTemplate('ko_table_empty', '\
                        <tbody data-bind="ifnot: hasRows">\
                           <tr>\
                               <td class="alert alert-info" data-bind="attr: { colspan : $root.filteredColumns().length }">No results to display</td>\
                            </tr>\
                        </tbody>');

    templateEngine.addTemplate('ko_table_foot', '<tfoot></tfoot>');

    templateEngine.addTemplate("ko_table_pager", '\
            <div class="row" data-bind="visible: hasRows() && showPager()">\
                <div class="col-md-6">\
                    <div class="btn-group" data-toggle="buttons" data-bind="foreach: $root.availablePageSizes()">\
                        <label class="btn btn-default" data-bind="css: { active: $data == $root.pageSize() }, click: function() { $root.pageSize($data) }" >\
                            <!-- ko if: $data == $root.pageSize() -->\
                                <input type="radio" name="table_pagesize_options" />\
                                <span data-bind="text: $data + \' \'"/>\
                            <!-- /ko -->\
                            <!-- ko if: $data != $root.pageSize() -->\
                                <input type="radio" name="table_pagesize_options"  />\
                                <span data-bind="text: $data + \' \'"/>\
                            <!-- /ko -->\
                        </label>\
                    </div>\
                    <span style="margin-left: 5px;">records per page</span>\
                </div>\
                <div class="col-md-6 text-right" data-bind="if: showPager">\
                    <ul class="pagination">\
                        <li data-bind="css: { disabled: isFirstPage() }">\
                            <a href="#" data-bind="click: prevPage"><i class="fa fa-chevron-circle-left"></i></a>\
                        </li>\
                        <!-- ko foreach: pages() -->\
                            <!-- ko if: $data == "ellipsis" -->\
                                <li>\
                                    <span><i class="fa fa-ellipsis-h"></i></span>\
                                </li>\
                            <!-- /ko -->\
                            <!-- ko if: $data != "ellipsis" -->\
                                <li data-bind="css: { active: $data === ($root.pageIndex() + 1)}">\
                                    <a href="#" data-bind="text: $data, click: $root.moveToPage"/>\
                                </li>\
                            <!-- /ko -->\
                        <!-- /ko -->\
                        <li data-bind="css: { disabled: isLastPage() }">\
                            <a href="#" data-bind="click: nextPage"><i class="fa fa-chevron-circle-right"></i></a>\
                        </li>\
                    </ul>\
                </div>\
            </div>');

    ko.bindingHandlers.dataTable = {
        init: function (element, valueAccessor) {
            valueAccessor().element(element);
            return { 'controlsDescendantBindings': true };
        },
        update: function (element, valueAccessor, allBindingsAccessor) {
            var viewModel = valueAccessor(), allBindings = allBindingsAccessor();

            var columnPickerTemplateName = allBindings.columnPickerTemplateName || "ko_column_picker",
                tableHeaderTemplateName = allBindings.tableHeaderTemplate || "ko_table_header",
                tableBodyTemplateName = allBindings.tableBodyTemplate || "ko_table_body",
                tableFooterTemplateName = allBindings.tableFooterTemplate || "ko_table_foot",
                tablePagerTemplateName = allBindings.tablePagerTemplate || "ko_table_pager",
                tableEmptyTemplateName = allBindings.emptyTemplate || 'ko_table_empty';

            // make sure the template exists
            columnPickerTemplateName = $('#' + columnPickerTemplateName).length ? columnPickerTemplateName : "ko_column_picker";
            tableHeaderTemplateName = $('#' + tableHeaderTemplateName).length ? tableHeaderTemplateName : "ko_table_header";
            tableBodyTemplateName = $('#' + tableBodyTemplateName).length ? tableBodyTemplateName : "ko_table_body";
            tableFooterTemplateName = $('#' + tableFooterTemplateName).length ? tableFooterTemplateName : "ko_table_foot";
            tablePagerTemplateName = $('#' + tablePagerTemplateName).length ? tablePagerTemplateName : "ko_table_pager";
            tableEmptyTemplateName = $('#' + tableEmptyTemplateName).length ? tableEmptyTemplateName : "ko_table_empty";

            var table = $(document.createElement('table')).addClass("table table-bordered table-hover")[0];

            // Render table header
            var headerContainer = table.appendChild(document.createElement("DIV"));
            ko.renderTemplate(tableHeaderTemplateName, viewModel, { templateEngine: templateEngine }, headerContainer, "replaceNode");

            // Render table body
            var bodyContainer = table.appendChild(document.createElement("DIV"));
            ko.renderTemplate(tableBodyTemplateName, viewModel, { templateEngine: templateEngine }, bodyContainer, "replaceNode");

            // Render table foot
            var footContainer = table.appendChild(document.createElement("DIV"));
            ko.renderTemplate(tableFooterTemplateName, viewModel, { templateEngine: templateEngine }, footContainer, "replaceNode");

            var emptyContainer = table.appendChild(document.createElement("table"));
            ko.renderTemplate(tableEmptyTemplateName, viewModel, { templateEngine: templateEngine },
                emptyContainer, "replaceNode");

            $(element).replaceWith($(table));

            viewModel.element(table);

            var columnPickerContainer = $('<div />').insertBefore($(table));
            ko.renderTemplate(columnPickerTemplateName, viewModel, { templateEngine: templateEngine }, columnPickerContainer[0], 'replaceNode');

            // Render table pager
            var pagerContainer = $('<div />').insertAfter($(table));
            ko.renderTemplate(tablePagerTemplateName, viewModel, { templateEngine: templateEngine }, pagerContainer[0], "replaceNode");
        }
    };
})();
